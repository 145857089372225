<template>
  <div class="info">
    <sdCards :title="i18n.t('partners.beWithUs')">
      <img :src="require('@/static/img/referral-program.png')" alt="" class="info__img" />
      <p class="info__text">{{ i18n.t('partners.textWithUsTwo') }}</p>
      <p class="info__text">{{ i18n.t('partners.textWithUsThree') }}</p>
      <div class="info__referral">
        <a-input size="large" type="text" :value="`${refferalLink[i18n.locale]}?ref=${userPartnerInfo.code}`"></a-input>
        <sdButton size="default" style="margin: 0 auto" type="success" @click="copyAddress">{{
          i18n.t('partners.copy')
        }}</sdButton>
      </div>
    </sdCards>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

const SalesTarget = {
  name: 'SalesTarget',
  props: {
    userPartnerInfo: Object,
  },
  setup(props) {
    const i18n = reactive(useI18n());
    const refferalLink = ref({
      en: process.env.VUE_APP_API_EN_REFERAL_SHARING_URL,
      ru: process.env.VUE_APP_API_RU_REFERAL_SHARING_URL,
    });

    const copyAddress = () => {
      window.navigator.clipboard.writeText(`${refferalLink.value[i18n.locale]}?ref=${props.userPartnerInfo.code}`);
      message.success(i18n.t('messages.linkCopied'));
    };
    return {
      i18n,
      copyAddress,
      refferalLink,
    };
  },
};

export default SalesTarget;
</script>
<style lang="scss" scoped>
.info {
  height: 100%;
  padding-bottom: 25px;
  .ant-card {
    height: 100%;
  }
  &__img {
    margin: 0 5% 20px;
    max-width: 90%;
    object-fit: cover;
  }
  &__referral {
    margin-top: 20px;
    display: flex;
    input {
      margin-right: 20px;
    }
    button {
      height: 50px;
      margin-left: 20px;
    }
  }
}
</style>
